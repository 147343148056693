import React from 'react'

import './ATCollaborator.css'

const ATCollaborator = (props) => {
    return (
        <div className="collaborator">
            <h6><a href={props.link} target="_blank" rel="noopener noreferrer">{props.name}</a></h6>
            <label>{props.position}<span style={{ color: '#E8531D' }}>.</span></label>
        </div>
    );
}

export default ATCollaborator;