import React from 'react'

import LinkButton from '../Button/LinkButton'

import './Footer.css'

const logoStar = require('../../images/logo-star.png')

const Footer = (props) => {

    return (
        <footer className="at-footer">
            <div className="container">
                <div className="row ">
                    <div className="col-12 col-lg-4">
                        <div className="copyright">
                            <img src={logoStar} alt="Logo" />
                            <span><span className="designed">designed by</span> <span className="orange">us</span> - &copy; All rights reserved 2019 <br /> ALFATAURI STUDIO - P.IVA 03666510791 </span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="footer-menu">
                            <LinkButton //className={l.active ? 'active' : ''}
                                key="link-to-0" href="#" onClick={() => props.onLinkClick('home')}>
                                Home
                            </LinkButton>
                            <LinkButton //className={l.active ? 'active' : ''}
                                key="link-to-1" href="#" onClick={() => props.onLinkClick('servizi')}>
                                Show Reel
                            </LinkButton>
                            <LinkButton //className={l.active ? 'active' : ''}
                                key="link-to-2" href="#" onClick={() => props.onLinkClick('team')}>
                                Servizi
                            </LinkButton>
                            <LinkButton //className={l.active ? 'active' : ''}
                                key="link-to-3" href="#" onClick={() => props.onLinkClick('contatti')}>
                                Contatti
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;