/* JS */
/* react */
import React from 'react';

/* third-party components */
import AOS from 'aos';
import { Waypoint } from 'react-waypoint';

/* custom components */
import Hamburger from '../components/Hamburger/Hamburger';
import OurNumbers from '../components/OurNumbers/OurNumbers';
import Footer from '../components/Footer/Footer';
import Footer2020 from '../components/Footer2020/Footer2020';

import ATCollaborator from '../components/ATCollaborator';
import ATLayout from '../components/ATLayout';
import SEO from '../components/seo';

/* STYLE */

/* third part styles */
import 'animate.css';
import 'aos/dist/aos.css';
/* custom styles */
import './team.css';

const andrea = require('../images/andrea.png');
const antonello = require('../images/antonello.png');
const mauro = require('../images/mauro.png');

const footerLinks = [
	{
		id: '1',
		text: 'home',
	},
	{
		id: '2',
		text: 'servizi',
	},
	{
		id: '3',
		text: 'team',
	},
	{
		id: '4',
		text: 'contatti',
	},
];

class TeamPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isMenuOpen: false,
		};

		this.handleOnHamburgerClick = this.handleOnHamburgerClick.bind(this);

		this.handleOnAstro1Enter = this.handleOnAstro1Enter.bind(this);
		this.handleOnAstro2Enter = this.handleOnAstro2Enter.bind(this);
		this.handleOnAstro3Enter = this.handleOnAstro3Enter.bind(this);

		this.handleOnSatellite1Enter = this.handleOnSatellite1Enter.bind(this);
		this.handleOnSatellite2Enter = this.handleOnSatellite2Enter.bind(this);

		this.handleOnOutsideMenuClick = this.handleOnOutsideMenuClick.bind(this);
	}

	componentDidMount() {
		AOS.init();
	}

	componentDidUpdate() {
		AOS.refresh();
	}

	handleOnHamburgerClick(event) {
		let imo = this.state.isMenuOpen;
		this.setState({
			isMenuOpen: !imo,
		});
	}

	handleOnAstro1Enter(event) {
		let astro = document.getElementById('astro1');
		if (!astro.classList.contains('animated')) astro.classList += ' animated';
	}

	handleOnAstro2Enter(event) {
		let astro = document.getElementById('astro2');
		if (!astro.classList.contains('animated')) astro.classList += ' animated';
	}

	handleOnAstro3Enter(event) {
		let astro = document.getElementById('astro3');
		if (!astro.classList.contains('animated')) astro.classList += ' animated';
	}

	handleOnSatellite1Enter(event) {
		let satellite = document.getElementById('satellite1');
		if (!satellite.classList.contains('animated')) satellite.classList += ' animated';
	}

	handleOnSatellite2Enter(event) {
		let satellite = document.getElementById('satellite2');
		if (!satellite.classList.contains('animated')) satellite.classList += ' animated';
	}

	handleOnOutsideMenuClick(event) {
		this.setState({
			isMenuOpen: false,
		});
	}

	render() {
		return (
			<ATLayout title="Team" isMenuOpen={this.state.isMenuOpen} onOutsideMenuClick={this.handleOnOutsideMenuClick}>
				<SEO
					title="Team • Alfatauri Studio"
					relPath="/contatti/"
					description="Crediamo nel gioco di squadra e in tutto ciò che questo può scaturire. Anche voi ne fate parte."
				/>
				<div className="at-page">
					<div className="container heading">
						<div className="row">
							<div className="col-8 col-lg-12">
								<h2 className="title">
									Great things
									<br />
									are never done
									<br />
									by one person<span style={{ color: '#E8531D' }}>.</span>
								</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-10 col-lg-6 col-xl-8">
								<h3 className="subtitle" data-aos="fade-up">
									Crediamo nel <b>gioco di squadra</b> e in tutto ciò che questo può scaturire.<br className="mobile"></br> <b>Anche voi</b>{' '}
									ne fate parte<span style={{ color: '#E8531D' }}>.</span>
								</h3>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row">
							<div className="col-12" data-aos="fade-left">
								<OurNumbers></OurNumbers>
							</div>
						</div>
						<div className="row">
							<div className="col-11 col-lg-4">
								<h4 data-aos="fade-right">
									Le nostre <b>brutte</b> facce<span style={{ color: '#E8531D' }}>.</span>
								</h4>
							</div>
						</div>
						<div className="row" style={{ marginBottom: '260px' }}>
							<div className="col-10 offset-1">
								<div className="row team-row mobile">
									<div className="bg-stars bg-far-stars"></div>
									<div className="bg-stars bg-near-stars"></div>

									<Waypoint onEnter={this.handleOnAstro2Enter} />
									<img id="astro2" className="decoration astro" src={require('../images/astro.svg')} alt="Astronaut Decoration" />

									<div className="col-6 team-member-column">
										<img className="team-member-pic" src={andrea} alt="Andrea Curcio" data-aos="zoom-in" />
									</div>
									<div className="col-6 team-member-column">
										<img className="team-member-pic" src={antonello} alt="Antonello Gatto" data-aos="zoom-in" />
									</div>
								</div>
								<div className="row team-descr-row mobile">
									<div className="col-5 offset-1 team-descr-column">
										<a href="https://www.linkedin.com/in/andrea-curcio-curcied/" alt="Linked-in" target="_blank" rel="noopener noreferrer">
											<h6>Andrea Curcio</h6>
											<p>
												Graphic Designer
												<br />
												&amp; UX/UI lover.
											</p>
										</a>
									</div>
									<div className="col-5 offset-1 team-descr-column">
										<a
											href="https://www.linkedin.com/in/antonello-gatto-0645b050/"
											alt="Linked-in"
											target="_blank"
											rel="noopener noreferrer"
										>
											<h6>Antonello Gatto</h6>
											<p>
												Computer Engineer
												<br />
												&amp; IT passionate.
											</p>
										</a>
									</div>
								</div>

								<div className="row team-row mobile">
									<div className="bg-stars bg-far-stars"></div>
									<div className="bg-stars bg-near-stars"></div>

									<Waypoint onEnter={this.handleOnSatellite2Enter} />
									<img id="satellite2" className="decoration satellite" src={require('../images/satellite.svg')} alt="Satellite Decoration" />

									<div className="col-6 offset-3 team-member-column">
										<img className="team-member-pic" src={mauro} alt="Mauro Iemboli" data-aos="zoom-in" />
									</div>
									{/* <div className="col-6 team-member-column">
				                    <img className="team-member-pic" src={require('../images/headshot.png')} alt="Team Member" />
                                </div> */}
								</div>
								<div className="row team-descr-row mobile">
									<div className="col-5 offset-4 team-descr-column">
										<a href="https://www.linkedin.com/in/mauro-iemboli-bbb58894/" alt="Linked-in" target="_blank" rel="noopener noreferrer">
											<h6>Mauro Iemboli</h6>
											<p>
												Computer Engineer
												<br />
												&amp; IT passionate.
											</p>
										</a>
									</div>
									{/* <div className="col-5 offset-1 team-descr-column">
                                    <h6>Andrea Curcio</h6>
                                    <p>Graphic Designer<br/>&amp; UX/UI lover.</p>
                                </div> */}
								</div>

								{/* <div className="row team-row mobile">
                                <div className="bg-stars bg-far-stars"></div>
                                <div className="bg-stars bg-near-stars"></div>

                                <Waypoint onEnter={this.handleOnAstro3Enter} />
                                <img id="astro3" className="decoration astro" src={require('../images/astro.svg')} alt="Astronaut Decoration" />

                                <div className="col-6 team-member-column">
				                    <img className="team-member-pic" src={require('../images/headshot.png')} alt="Team Member" />
                                </div>
                                <div className="col-6 team-member-column">
				                    <img className="team-member-pic" src={require('../images/headshot.png')} alt="Team Member" />
                                </div>
                            </div>
                            <div className="row team-descr-row mobile">
                                <div className="col-5 offset-1 team-descr-column">
                                    <h6>Andrea Curcio</h6>
                                    <p>Graphic Designer<br/>&amp; UX/UI lover.</p>
                                </div>
                                <div className="col-5 offset-1 team-descr-column">
                                    <h6>Andrea Curcio</h6>
                                    <p>Graphic Designer<br/>&amp; UX/UI lover.</p>
                                </div>
                            </div> */}

								<div className="row team-row desktop">
									<div className="bg-stars bg-far-stars"></div>
									<div className="bg-stars bg-near-stars"></div>

									<Waypoint onEnter={this.handleOnAstro1Enter} />
									<img id="astro1" className="decoration astro" src={require('../images/astro.svg')} alt="Astronauta" />

									<div className="col-4 team-member-column">
										<img className="team-member-pic" src={andrea} alt="Andrea Curcio" data-aos="zoom-in" />
									</div>
									<div className="col-4 team-member-column">
										<img className="team-member-pic" src={antonello} alt="Antonello Gatto" data-aos="zoom-in" />
									</div>
									<div className="col-4 team-member-column">
										<img className="team-member-pic" src={mauro} alt="Mauro Iemboli" data-aos="zoom-in" />
									</div>
								</div>
								<div className="row team-descr-row desktop">
									<div className="col-3 offset-1 team-descr-column">
										<a href="https://www.linkedin.com/in/andrea-curcio-curcied/" alt="Linked-in" target="_blank" rel="noopener noreferrer">
											<h6>Andrea Curcio</h6>
											<p>
												Graphic Designer
												<br />
												&amp; UX/UI lover.
											</p>
										</a>
									</div>
									<div className="col-3 offset-1 team-descr-column">
										<a
											href="https://www.linkedin.com/in/antonello-gatto-0645b050/"
											alt="Linked-in"
											target="_blank"
											rel="noopener noreferrer"
										>
											<h6>Antonello Gatto</h6>
											<p>
												Computer Engineer
												<br />
												&amp; IT passionate.
											</p>
										</a>
									</div>
									<div className="col-3 offset-1 team-descr-column">
										<a href="https://www.linkedin.com/in/mauro-iemboli-bbb58894/" alt="Linked-in" target="_blank" rel="noopener noreferrer">
											<h6>Mauro Iemboli</h6>
											<p>
												Computer Engineer
												<br />
												&amp; IT passionate.
											</p>
										</a>
									</div>
								</div>

								{/*<div className="row team-row desktop">
                                <div className="bg-stars bg-far-stars"></div>
                                <div className="bg-stars bg-near-stars"></div>

                                <Waypoint onEnter={this.handleOnSatellite1Enter} />
                                <img id="satellite1" className="decoration satellite" src={require('../images/satellite.svg')} alt="Satellite Decoration"/>

                                <div className="col-4 team-member-column">
				                    <img className="team-member-pic" src={require('../images/headshot.png')} alt="Team Member" />
                                </div>
                                <div className="col-4 team-member-column">
				                    <img className="team-member-pic" src={require('../images/headshot.png')} alt="Team Member" />
                                </div>
                                <div className="col-4 team-member-column">
				                    <img className="team-member-pic" src={require('../images/headshot.png')} alt="Team Member" />
                                </div>
                            </div>
                            <div className="row team-descr-row desktop">
                                <div className="col-3 offset-1 team-descr-column">
                                    <h6>Andrea Curcio</h6>
                                    <p>Graphic Designer<br/>&amp; UX/UI lover.</p>
                                </div>
                                <div className="col-3 offset-1 team-descr-column">
                                    <h6>Andrea Curcio</h6>
                                    <p>Graphic Designer<br/>&amp; UX/UI lover.</p>
                                
                                </div>
                                <div className="col-3 offset-1 team-descr-column">
                                    <h6>Andrea Curcio</h6>
                                    <p>Graphic Designer<br/>&amp; UX/UI lover.</p>
                                </div>
                            </div> */}
							</div>
						</div>
						<div className="row">
							<div className="col-11">
								<h4 className="text-right" data-aos="fade-left" style={{ marginBottom: '150px' }}>
									Possiamo <b>contare</b> su<span style={{ color: '#E8531D' }}>.</span>
								</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-10 offset-1">
								<div className="row">
									<div className="col-12 d-flex justify-content-around desktop">
										<ATCollaborator name="Aleana Percivalle" position="Graphic Designer" link="https://behance.net/aleanapercb879" />
										<ATCollaborator name="Giulio Del Prete" position="Illustrator" link="https://www.behance.net/giuliodelp639c" />
										{/* <ATCollaborator
											name="Maria Lucrezia Ferrari"
											position="Marketer"
											link="https://www.linkedin.com/in/maria-lucrezia-ferrari-503128118/"
										/> */}
									</div>
									<div className="col-12 d-flex justify-content-around desktop">
										<ATCollaborator name="Marco Maddalena" position="Graphic Designer" />
										<ATCollaborator name="Antonello Mastrogiacomo" position="Graphic Designer" />
									</div>
									<div className="col-6 mobile">
										<ATCollaborator name="Aleana Percivalle" position="Graphic Designer" link="https://behance.net/aleanapercb879" />
									</div>
									<div className="col-6 mobile">
										<ATCollaborator name="Giulio Del Prete" position="Illustrator" link="https://www.behance.net/giuliodelp639c" />
									</div>
									{/* <div className="col-6 mobile">
										<ATCollaborator
											name="Maria Lucrezia Ferrari"
											position="Marketer"
											link="https://www.linkedin.com/in/maria-lucrezia-ferrari-503128118/"
										/>
									</div> */}
									<div className="col-6 mobile">
										<ATCollaborator name="Marco Maddalena" position="Graphic Designer" />
									</div>
									<div className="col-6 mobile">
										<ATCollaborator name="Antonello Mastrogiacomo" position="Graphic Designer" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<Hamburger
						open={this.state.isMenuOpen} //className={this.props.isMenuOpen ? 'open' : ''}
						onClick={this.handleOnHamburgerClick}
					/>
				</div>
				<Footer2020></Footer2020>
			</ATLayout>
		);
	}
}

export default TeamPage;
